// Js scripts for the theme
// Menu Dropdown
const dropdown = document.querySelector('.mobile-menu'),
      submenu = document.querySelector('.sub-menu'),
      buttonClick = document.querySelector('.check-button'),
      curtainClick = document.querySelector('#menu-curtain'),
      hamburger = document.querySelector('.menu-topo'),
      curtain = document.querySelector('#menu-curtain');

function toggleMenu() {
  dropdown.classList.toggle('show-dropdown');
  hamburger.classList.toggle('animate-button');
  curtain.classList.toggle('menu-curtain--opened');
  curtain.classList.toggle('menu-curtain--animate');
}

buttonClick.addEventListener('click', toggleMenu);
curtainClick.addEventListener('click', toggleMenu);

document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') { // Verifica se a tecla "Esc" foi pressionada
      if (dropdown.classList.contains('show-dropdown')) { // Verifica se o menu está aberto
        toggleMenu(); // Chama a função para esconder o menu
      }
    }
  });

// Adicionando itens na cotação do café
let cotacao = document.querySelector('.cotacao1 .fonte');
cotacao.innerHTML += '<div>fonte: <a href="https://www.cepea.esalq.usp.br/br/indicador/cafe.aspx" target="_blank">Cepea</a></div>';

// Caixa de pesquisa
let sClick = document.querySelector('.pesquisa i'), 
sBox = document.querySelector('.search-box'); 

sClick.addEventListener( 'click', () => {
    sBox.classList.toggle('show-box');
});

sClick.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
        sBox.classList.toggle('show-box');
    }
});

document.addEventListener('click', (event) => {
    // Verifica se o clique ocorreu fora da caixa de pesquisa
    if (!sBox.contains(event.target) && !sClick.contains(event.target)) {
      sBox.classList.remove('show-box');
    }
});

document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') { // Verifica se a tecla "Esc" foi pressionada
      if (sBox.classList.contains('show-box')) { // Verifica se o menu está aberto
        sBox.classList.remove('show-box');
      }
    }
  });